var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "helpCenter" },
    [
      _c(
        "div",
        { staticClass: "tabs" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.channelNo,
                callback: function ($$v) {
                  _vm.channelNo = $$v
                },
                expression: "channelNo",
              },
            },
            _vm._l(_vm.channelList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.channelName, name: item.channelNo },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "question-box" }, [
        _c(
          "div",
          { staticClass: "group" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addGroup },
              },
              [_vm._v("新增问题组")]
            ),
            _c(
              "div",
              { staticClass: "tree" },
              [
                _c("el-tree", {
                  attrs: {
                    data: _vm.data,
                    "render-content": _vm.renderContent,
                  },
                  on: { "node-click": _vm.handleNodeClick },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "question" },
          _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _c("div", {
                  staticClass: "msg",
                  domProps: { innerHTML: _vm._s(item.msgContent) },
                }),
              ]),
              _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.editQuestion(item)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteIssue(item.id)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _vm.show1
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.show1,
                "close-on-click-modal": false,
                title: _vm.title,
                width: "450px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show1 = $event
                },
                close: _vm.cancelFun,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form1",
                  staticClass: "demo-form-inline",
                  staticStyle: { padding: "20px" },
                  attrs: {
                    model: _vm.form1,
                    rules: _vm.rules,
                    "label-position": "right",
                    "label-width": "95px",
                  },
                },
                [
                  _vm.title == "新增问题组" || _vm.title == "编辑问题组"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "问题组名称：", prop: "title" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              placeholder: "请输入问题组名称",
                            },
                            model: {
                              value: _vm.form1.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form1, "title", $$v)
                              },
                              expression: "form1.title",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.title == "新增问题分类" || _vm.title == "编辑问题分类"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "问题分类：", prop: "title" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              placeholder: "请输入问题分类",
                            },
                            model: {
                              value: _vm.form1.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form1, "title", $$v)
                              },
                              expression: "form1.title",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.cancelFun } },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.confirmFun },
                    },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.show2
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.show2,
                "close-on-click-modal": false,
                title: _vm.title,
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show2 = $event
                },
                close: _vm.cancelFun2,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form2",
                  staticClass: "demo-form-inline",
                  staticStyle: { padding: "20px" },
                  attrs: {
                    model: _vm.form2,
                    rules: _vm.rules,
                    "label-position": "right",
                    "label-width": "95px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "问题名称：", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          placeholder: "请输入问题名称",
                        },
                        model: {
                          value: _vm.form2.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "title", $$v)
                          },
                          expression: "form2.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "问题回答：", prop: "msgContent" } },
                    [
                      _c(
                        "div",
                        { staticClass: "edit-box" },
                        [
                          _c("quill-editor", {
                            ref: "myTextEditor",
                            staticStyle: { height: "250px" },
                            attrs: { options: _vm.editorOption },
                            model: {
                              value: _vm.form2.msgContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form2, "msgContent", $$v)
                              },
                              expression: "form2.msgContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.cancelFun2 } },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.confirmFun2 },
                    },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }