<template>
  <div class="helpCenter">
    <div class="tabs">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="question-box">
      <div class="group">
        <el-button type="primary" size="small" @click="addGroup"
          >新增问题组</el-button
        >
        <div class="tree">
          <el-tree
            :data="data"
            :render-content="renderContent"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
      <div class="question">
        <div v-for="(item, index) in list" :key="index" class="item">
          <div class="left">
            <div class="label">{{ item.title }}</div>
            <div v-html="item.msgContent" class="msg"></div>
          </div>
          <div class="btns">
            <el-button type="text" @click="editQuestion(item)">编辑</el-button>
            <el-button type="text" @click="deleteIssue(item.id)"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="show1"
      :visible.sync="show1"
      :close-on-click-modal="false"
      :title="title"
      width="450px"
      @close="cancelFun"
    >
      <el-form
        ref="form1"
        :model="form1"
        :rules="rules"
        class="demo-form-inline"
        label-position="right"
        label-width="95px"
        style="padding: 20px"
      >
        <el-form-item
          v-if="title == '新增问题组' || title == '编辑问题组'"
          label="问题组名称："
          prop="title"
        >
          <el-input
            v-model="form1.title"
            maxlength="10"
            placeholder="请输入问题组名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="title == '新增问题分类' || title == '编辑问题分类'"
          label="问题分类："
          prop="title"
        >
          <el-input
            v-model="form1.title"
            maxlength="10"
            placeholder="请输入问题分类"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelFun">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmFun"
          >确 认</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-if="show2"
      :visible.sync="show2"
      :close-on-click-modal="false"
      :title="title"
      width="800px"
      @close="cancelFun2"
    >
      <el-form
        ref="form2"
        :model="form2"
        :rules="rules"
        class="demo-form-inline"
        label-position="right"
        label-width="95px"
        style="padding: 20px"
      >
        <el-form-item label="问题名称：" prop="title">
          <el-input
            v-model="form2.title"
            maxlength="50"
            placeholder="请输入问题名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题回答：" prop="msgContent">
          <div class="edit-box">
            <quill-editor
              ref="myTextEditor"
              v-model="form2.msgContent"
              :options="editorOption"
              style="height: 250px"
            ></quill-editor>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelFun2">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmFun2"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getCurrentUserChannelInfoAPI,
  helpCenterTree,
  deleteHelpCenterById,
  saveHelpCenter,
  updateHelpCenter,
} from "@/api/lib/api.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      channelList: [],
      channelNo: "",
      data: [],
      list: [],
      show1: false,
      show2: false,
      title: "",
      form1: {
        title: "",
        class: [{ value: "" }],
        nodeType: 1,
        channelNo: this.channelNo,
        parentId: null,
        msgContent: "",
      },
      form2: {
        title: "",
        nodeType: 3,
        channelNo: this.channelNo,
        msgContent: "",
        parentId: null,
      },
      editorOption: {
        placeholder: "编辑内容",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
          ],
        },
      },
      rules: {
        title: { required: true, message: "请输入问题组名称", trigger: "blur" },
        msgContent: {
          required: true,
          validator(rule, value, callback) {
            if (!value) callback("请输入问题答案");
            else callback();
          },
          trigger: "blur",
        },
      },
    };
  },
  mounted() {
    this.getQueryChannelList();
  },
  methods: {
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.channelNo = this.channelList[0].channelNo;
          this.renderData();
        }
      });
    },
    handleClick() {
      this.renderData();
      this.list = [];
    },
    renderData() {
      helpCenterTree({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.data = res.data.map((i) => {
            if (i.children) {
              i.children.forEach((e) => {
                e.children = [];
              });
            }
            return i;
          });
        }
      });
    },
    renderContent(h, { data }) {
      return (
        <span class="custom-tree-node">
          <span>{data.title}</span>
          <el-dropdown on-command={(e) => this.handleCommand(e, data)}>
            <i class="iconfont its_prf_gengduo"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="add">新增</el-dropdown-item>
              <el-dropdown-item command="edit">编辑</el-dropdown-item>
              <el-dropdown-item command="delete">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      );
    },
    handleNodeClick(node) {
      if (node.nodeType == 2) {
        const data = { channelNo: this.channelNo, parentId: node.id };
        helpCenterTree(data).then((res) => {
          if (res.code == "SUCCESS") {
            this.list = res.data || [];
          }
        });
      } else {
        this.list = [];
      }
    },
    handleCommand(e, node) {
      if (e == "add") this.addNode(node);
      else if (e == "edit") this.editNode(node);
      else this.deleteNode(node.id);
    },
    addNode(node) {
      if (node.nodeType == 2) this.addQuestion(node);
      else {
        this.show1 = true;
        this.title = "新增问题分类";
        this.form1 = {
          title: "",
          nodeType: 2,
          channelNo: this.channelNo,
          parentId: node.id,
        };
      }
    },
    editNode(node) {
      this.show1 = true;
      if (node.nodeType == 1) this.title = "编辑问题组";
      else this.title = "编辑问题分类";
      this.form1 = this.deepClone(node);
    },
    deleteNode(id) {
      this.$confirm("删除后，已添加的问题也一起删除", "提示", {
        type: "warning",
      }).then(() => {
        deleteHelpCenterById({ id }).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("删除成功");
            this.list = [];
            this.renderData();
          }
        });
      });
    },
    addGroup() {
      this.show1 = true;
      this.title = "新增问题组";
      this.form1 = {
        title: "",
        nodeType: 1,
        channelNo: this.channelNo,
        parentId: null,
      };
    },
    addQuestion(node) {
      this.show2 = true;
      this.title = "新增问题";
      this.form2 = {
        title: "",
        nodeType: 3,
        channelNo: this.channelNo,
        msgContent: "",
        parentId: node.id,
      };
    },
    editQuestion(item) {
      this.show2 = true;
      this.title = "编辑问题";
      this.form2 = this.deepClone(item);
    },
    cancelFun() {
      this.show1 = false;
    },
    cancelFun2() {
      this.show2 = false;
    },
    confirmFun() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          if (this.title == "新增问题组" || this.title == "新增问题分类") {
            saveHelpCenter(this.form1).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.show1 = false;
              }
            });
          } else {
            updateHelpCenter(this.form1).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.show1 = false;
              }
            });
          }
        }
      });
    },
    confirmFun2() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          if (this.form2.msgContent.includes("<img ")) {
            this.$message.warning("请输入文字！");
            return;
          }
          if (this.title == "新增问题") {
            saveHelpCenter(this.form2).then((res) => {
              if (res.code == "SUCCESS") {
                this.handleNodeClick({
                  id: this.form2.parentId,
                  nodeType: 2,
                });
                this.show2 = false;
              }
            });
          } else {
            updateHelpCenter(this.form2).then((res) => {
              if (res.code == "SUCCESS") {
                this.handleNodeClick({
                  id: this.form2.parentId,
                  nodeType: 2,
                });
                this.show2 = false;
              }
            });
          }
        }
      });
    },
    deleteIssue(id) {
      this.$confirm("确认删除该问题？", "提示", {
        type: "warning",
      }).then(() => {
        deleteHelpCenterById({ id }).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("删除成功");
            this.handleNodeClick({ id: this.form2.parentId, nodeType: 2 });
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.helpCenter {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  display: flex;
  flex-direction: column;
  .tabs {
    flex-shrink: 0;
    /deep/.el-tabs__header {
      margin-bottom: 0;
    }
  }
  .question-box {
    flex: 1;
    display: flex;
    overflow: auto;
    .group {
      padding-top: 20px;
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid #0000001f;
      .tree {
        flex: 1;
        width: 100%;
        margin-top: 20px;
        padding: 0 10px;
        box-sizing: border-box;
        overflow: auto;
        /deep/.el-tree {
          .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;
            .its_prf_gengduo {
              font-weight: bold;
              &:hover {
                color: #336ffe;
              }
            }
          }
        }
      }
    }
    .question {
      padding: 20px;
      flex: 1;
      box-sizing: border-box;
      overflow: auto;
      .item {
        border-radius: 5px;
        background-color: #00000007;
        padding: 20px;
        display: flex;
        box-sizing: border-box;
        margin-bottom: 10px;
        &:hover {
          background-color: #00000015;
        }
        .left {
          flex: 1;
          .label {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
          }
          .msg {
            word-break: break-all;
          }
        }
        .btns {
          height: inherit;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
  }
  .add {
    color: #336ffe;
    cursor: pointer;
    padding-left: 95px;
  }

  .edit-box {
    height: 350px;
  }
  .el-input {
    width: 200px;
  }
}
</style>
